/** @jsx $jsx */
import { $jsx } from 'framework7'; // script must return/export component function

function framework7Component(props, _ref) {
  var $f7 = _ref.$f7,
      $on = _ref.$on;
  var title = 'Component Page';
  var names = ['John', 'Vladimir', 'Timo'];
  var phonenumber = localStorage.getItem('phone');
  var type = localStorage.getItem('type');

  var openAlert = function openAlert() {
    $f7.dialog.alert('Hello world!');
  };

  $on('pageInit', function () {
    function getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];

        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }

      return "";
    }

    function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var dt = new Date();
      dt.setMinutes(dt.getMinutes() + 30);
      var expires = "expires=" + d.toGMTString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";";
    }

    $("#otpresend").click(function () {
      $("#otpresend").hide();
      $f7.preloader.show();
      $f7.request.post('https://appapi.shiners-uae.com/api/post.aspx', {
        phone: '' + phonenumber + '',
        mode: 'resend-pin',
        tempID: localStorage.getItem('tempid'),
        randomid: Math.random()
      }, function (data) {
        $f7.preloader.hide();
        $("#otpcountercontainer").show();
        beginCounter();
      });
    });
    $("#testfill").click(function () {
      $(".otopcode").val("1234");
    });

    function otpToken() {
      var value = $(".otopcode").val();

      if (value.length == 4) {
        $f7.dialog.alert('Hello world!');
      }
    }

    var myTimerSubmit = setInterval(function () {
      checkSubmit();
    }, 500);

    function checkSubmit() {
      var value = $(".otopcode").val();

      if (value.length == 4) {
        $f7.preloader.show();
        $f7.request.post('https://appapi.shiners-uae.com/api/post.aspx', {
          phone: '' + phonenumber + '',
          otp: '' + $(".otopcode").val() + '',
          mode: 'verify-pin',
          tempID: localStorage.getItem('tempid'),
          randomid: Math.random()
        }, function (data) {
          $f7.preloader.hide();
          var dataJSON = '' + data + '';
          var obj = JSON.parse(dataJSON, function (key, value) {
            return value;
          });

          if (obj.type == "verified") {
            $(".otopcode").blur();
            $(".otopcode").focusout();
            setCookie('phone', '' + getCookie("phone") + '', '365');
            setCookie('verified', 'True', '365');
            localStorage.setItem('phone', phonenumber);
            localStorage.setItem('verified', 'True');

            if (obj.firstname == "" && obj.lastname == "" && obj.email == "") {
              localStorage.setItem('status', 'loggedin');
              $(".otopcode").val("");
              clearInterval(myTimerSubmit);
              $f7.views.main.router.navigate('/profile/', {
                ignoreCache: true
              });
            } else {
              if (type == "existing") {
                setCookie('status', 'loggedin', '365');
                localStorage.setItem('status', 'loggedin');
                $(".otopcode").val("");
                clearInterval(myTimerSubmit);
                $f7.views.main.router.navigate('/dynamic-route/page/home/', {
                  ignoreCache: true
                });
              } else if (type == "new") {
                $(".otopcode").val("");
                clearInterval(myTimerSubmit);
                $f7.views.main.router.navigate('/profile/', {
                  ignoreCache: true
                });
              } else {
                $(".otopcode").val("");
                clearInterval(myTimerSubmit);
                $f7.views.main.router.navigate('/profile/', {
                  ignoreCache: true
                });
              }
            }
          } else if (obj.type == "invalidotp") {
            $(".otopcode").val("");
            $("#errorholder").html("<span style='color:red'>Invalid Pin</span>");
          } else {
            $(".otopcode").val("");
            $("#errorholder").html("<span style='color:red'>Unable to Process.</span>");
          }
        });
      }
    }

    $(".otopcode").on("change paste keyup", function () {
      $("#errorholder").html("");
    }); //$(".otopcode").on("change paste keyup", function () {
    //    var value = $(this).val();
    //    if (value.length == 4) {
    //        $f7.preloader.show();
    //        $f7.request.post('https://appapi.shiners-uae.com/api/post.aspx', { phone: '' + phonenumber + '', otp: '' + $(".otopcode").val() + '', mode: 'verify-pin', tempID: localStorage.getItem('tempid'), randomid: Math.random() }, function (data) {
    //            $f7.preloader.hide();
    //            const dataJSON = '' + data + '';
    //            const obj = JSON.parse(dataJSON, function (key, value) {
    //                return (value);
    //            });
    //            if (obj.type == "verified") {
    //                $(".otopcode").blur();
    //                $(".otopcode").focusout();
    //                setCookie('phone', '' + getCookie("phone") + '', '365');
    //                setCookie('verified', 'True', '365');
    //                localStorage.setItem('phone', phonenumber);
    //                localStorage.setItem('verified', 'True');
    //                if (obj.firstname == "" && obj.lastname == "" && obj.email == "") {
    //                    localStorage.setItem('status', 'loggedin');
    //                    $f7.views.main.router.navigate('/profile/', { ignoreCache: true });
    //                }
    //                else {
    //                    if (type == "existing") {
    //                        setCookie('status', 'loggedin', '365');
    //                        localStorage.setItem('status', 'loggedin');
    //                        $f7.views.main.router.navigate('/dynamic-route/page/home/', { ignoreCache: true });
    //                    }
    //                    else if (type == "new") {
    //                        $f7.views.main.router.navigate('/profile/', { ignoreCache: true });
    //                    }
    //                    else {
    //                        $f7.views.main.router.navigate('/profile/', { ignoreCache: true });
    //                    }
    //                }
    //            }
    //            else if (obj.type == "invalidotp") {
    //                $("#errorholder").html("<span style='color:red'>Invalid Pin</span>")
    //            }
    //            else {
    //                $("#errorholder").html("<span style='color:red'>Unable to Process.</span>")
    //            }
    //        });
    //    }
    //});

    function beginCounter() {
      $('#otpcounter').html("");
      var myTimer,
          timing = 60;
      $('#otpcounter').html(timing);
      myTimer = setInterval(function () {
        --timing;
        $('#otpcounter').html(timing);

        if (timing === 0) {
          $('#otpcountercontainer').hide();
          $('#otpresend').show(); //alert('Too late! Try again');

          clearInterval(myTimer); //$('#begin').prop('disabled', false);
        }
      }, 1000);
    }

    $(function () {
      beginCounter();
    });
  });
  $on('pageAfterOut', function () {// page has left the view
  }); // component function must return render function

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page no-swipeback" data-name="about">

        <div class="page-content ">
            <div class="shine-navbar shine-page-padding inner">


                <div class="row" style="position:relative;height:90px;">

                    <div class="col-50" style="position:absolute;bottom:0px;left:0px;">
                        <a href="#" class="link back">
                            <img src="static/back.png" style="height:15px;" />
                        </a>
                    </div>
                    <div class="col-50" style="position:absolute;bottom:4px;right:0px;"><img style="height:30px;float:right;" src="static/logo_small.png" /></div>
                </div>



            </div>

            <div class="shine-page-padding">

                <div class="shine-step" style="padding-top:115px;" id="testfill">
                    One step Closer<br />
                    to your Shine!
                </div>
                <div class="shine-enter">
                    Enter the 4 Digit OTP
                    sent to your number.
                </div>
                <div class="shine-code">
                    <input type="tel" class="otopcode" maxlength="4" required />
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>

                <div id="errorholder"></div>

                <div class="shine-resend">
                    <div id="otpcountercontainer" style="font-size:12px;">Resend code in 00:<span id="otpcounter"></span></div>
                    <a id="otpresend" href="" style="display:none;">Resend Code</a>
                </div>

            </div>
        </div>
    </div>
`
    }
    ;
}

framework7Component.id = '67dbca3c65';
framework7Component.style = "\n";
export default framework7Component;