/** @jsx $jsx */
import { $jsx } from 'framework7'; // script must return/export component function

function framework7Component(props, _ref) {
  var $$ = _ref.$$,
      $f7 = _ref.$f7,
      $on = _ref.$on;
  var title = 'Component Page'; //const names = ['John', 'Vladimir', 'Timo'];

  var autocompleteDropdownAll;
  var autocompleteDropdownAjaxTypeahead;
  var phonenumber = localStorage.getItem('phone');
  var years = '2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000, 1999, 1998, 1997, 1996, 1995, 1994, 1993, 1992, 1991, 1990, 1989, 1988, 1987, 1986, 1985, 1984, 1983, 1982, 1981, 1980, 1979, 1978, 1977, 1976, 1975, 1974, 1973, 1972, 1971'.split(', ');
  var names;
  var fruits = [];
  $f7.request.get('https://appapi.shiners-uae.com/api/json.aspx?mode=manufacturers', function (data) {
    names = data;
    var nameArr = names.split(',');

    for (var i = 0; i < nameArr.length; i++) {
      fruits.push(nameArr[i]);
    }
  });

  var openAlert = function openAlert() {
    $f7.dialog.alert('Hello world!');
  };

  $on('pageInit', function () {
    function getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];

        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }

      return "";
    }

    function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var dt = new Date();
      dt.setMinutes(dt.getMinutes() + 30);
      var expires = "expires=" + d.toGMTString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    if (localStorage.getItem('status') == "loggedin") {
      $(".btn-addvehicle").html("Add Vehicle");
      $(".step-add-vehicle").html("Add New Vehicle");
    }

    $("#UploadLink").attr("href", "url.html#appvehicleupload|__" + localStorage.getItem('phone') + "__" + localStorage.getItem('tempid') + "__" + localStorage.getItem('status') + "");
    autocompleteDropdownAll = $f7.autocomplete.create({
      inputEl: '#shine-year',
      openIn: 'dropdown',
      source: function source(query, render) {
        var results = []; // Find matched items

        for (var i = 0; i < years.length; i++) {
          if (years[i].toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(years[i]);
        } // Render items by passing array with result items


        render(results);
      }
    });
    autocompleteDropdownAll = $f7.autocomplete.create({
      inputEl: '#autocomplete-dropdown-all',
      openIn: 'dropdown',
      source: function source(query, render) {
        var results = []; // Find matched items

        for (var i = 0; i < fruits.length; i++) {
          if (fruits[i].toLowerCase().indexOf(query.toLowerCase()) >= 0) results.push(fruits[i]);
        } // Render items by passing array with result items


        render(results);
      }
    });

    function get_models(manuName) {
      $("#autocomplete-dropdown-models").val("");
      $f7.autocomplete.destroy("#autocomplete-dropdown-models");
      var namesModels;
      var models = [];
      $f7.request.get('https://appapi.shiners-uae.com/api/json.aspx?mode=models&manufacturer=' + manuName + '', function (data) {
        namesModels = data;
        var nameArrModels = namesModels.split(',');

        for (var i = 0; i < nameArrModels.length; i++) {
          models.push(nameArrModels[i]);
        }
      });
      autocompleteDropdownAll = $f7.autocomplete.create({
        inputEl: '#autocomplete-dropdown-models',
        openIn: 'dropdown',
        source: function source(query, render) {
          var resultsModels = []; // Find matched items

          for (var i = 0; i < models.length; i++) {
            if (models[i].toLowerCase().indexOf(query.toLowerCase()) >= 0) resultsModels.push(models[i]);
          } // Render items by passing array with result items


          render(resultsModels);
        }
      });
    }

    $(document).on("change", "#autocomplete-dropdown-all", function () {
      get_models($(this).val());
    });
    $(document).on("click", "#thiscar", function () {
      $("#shine-vehicletype").val("0");
      $("#thiscar").addClass("active");
      $("#thissuv").removeClass("active");
    });
    $(document).on("click", "#thissuv", function () {
      $("#shine-vehicletype").val("1");
      $("#thissuv").addClass("active");
      $("#thiscar").removeClass("active");
    });
    $("#btn-addvehicle").click(function () {
      var isvalid = true;

      if ($("#autocomplete-dropdown-all").val() == undefined || $("#autocomplete-dropdown-all").val() == "") {
        isvalid = false;
        $(".shiner-form.manufacturer").css("border-color", "#ff0000");
      } else {
        $(".shiner-form.manufacturer").css("border-color", "");
      }

      if ($("#autocomplete-dropdown-models").val() == undefined || $("#autocomplete-dropdown-models").val() == "") {
        isvalid = false;
        $(".shiner-form.model").css("border-color", "#ff0000");
      } else {
        $(".shiner-form.model").css("border-color", "");
      }

      if ($("#shine-year").val() == undefined || $("#shine-year").val() == "") {
        isvalid = false;
        $(".shiner-form.year").css("border-color", "#ff0000");
      } else {
        $(".shiner-form.year").css("border-color", "");
      }

      if ($("#shine-plate").val() == undefined || $("#shine-plate").val() == "") {
        isvalid = false;
        $(".shiner-form.plate").css("border-color", "#ff0000");
      } else {
        $(".shiner-form.plate").css("border-color", "");
      }

      if (isvalid == true) {
        $f7.preloader.show();
        $f7.request.post('https://appapi.shiners-uae.com/api/post.aspx', {
          phone: '' + phonenumber + '',
          manufacturer: '' + $("#autocomplete-dropdown-all").val() + '',
          model: '' + $("#autocomplete-dropdown-models").val() + '',
          year: '' + $("#shine-year").val() + '',
          plate: '' + $("#shine-plate").val() + '',
          vehicletype: '' + $("#shine-vehicletype").val() + '',
          mode: 'add-vehicle',
          tempid: localStorage.getItem('tempid'),
          vehicleImage: '' + $("#shine-vehicleimage").val() + ''
        }, function (data) {
          $f7.preloader.hide();

          if (data == "added") {
            setCookie('type', 'existing', '365');
            setCookie('status', 'loggedin', '365');
            localStorage.setItem('status', 'loggedin');
            localStorage.setItem('type', 'existing');
            $f7.views.main.router.navigate('/dynamic/page/home/' + Math.random() + '/', {
              ignoreCache: true
            });
          } else {
            $("#errorholder_vehicle").html("<span style='color:red'>Unable to Process.</span>");
          }
        });
      }
    });
  });
  $on('pageAfterOut', function () {// page has left the view
  }); // component function must return render function

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page shine-add-vehicle no-swipeback" data-name="vehicle">
        <div class="page-content">
            <div class="shine-navbar shine-page-padding inner">


                <div class="row" style="position:relative;height:90px;">

                    <div class="col-50 shine-back"  style="position:absolute;bottom:0px;left:0px;">
                        <a href="#" class="link back">
                            <img src="static/back.png" style="height:15px;" />
                        </a>
                    </div>
                    <div class="col-50 shine-logo"  style="position:absolute;bottom:4px;right:0px;"><img src="static/logo_small.png" /></div>
                </div>



            </div>


            <div class="shine-page-padding">

                <div class="shine-step step-add-vehicle" style="padding-top:115px;">
                    Your Vehicle
                </div>

            </div>
            <div class="shine-page-padding">
                <div class="row">
                    <div class="col-100 shine-vtab">
                        <a href="" id="thiscar" class="car active"><img src="static/car.png" /></a>  <a id="thissuv" href="" class="suv"><img src="static/suv.png" /></a>
                        <input type="hidden" id="shine-vehicletype" value="0" />
                    </div>
                </div>

                <div class="row">

                    <div class="col-100">
                        <div class="shiner-form manufacturer">
                            <input type="text" name="shine-manufacturer" id="autocomplete-dropdown-all" placeholder="Manufacturer" style="width:100%" />
                        </div>
                    </div>


                </div>

                <div class="row">

                    <div class="col-100">
                        <div class="shiner-form model">
                            <input type="text" name="shine-model" id="autocomplete-dropdown-models" placeholder="Model" style="width:100%" />
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-100">
                        <div class="shiner-form year">
                            <input type="text" name="shine-year" id="shine-year" placeholder="Year" style="width:100%" />
                        </div>
                    </div>
                </div>


                <div class="row">

                    <div class="col-100">
                        <div class="shiner-form plate">
                            <input type="text" name="shine-plate" id="shine-plate" placeholder="Number Plate" />
                        </div>
                    </div>

                    <div id="errorholder_vehicle"></div>
                </div>


                <div class="row">
                    <div class="col-100" id="uploaded_photo" style="margin-top:10px;margin-block:10px;"></div>
                        <div class="col-100">
                          
                            <div style="float:left;width:50px;height:50px;">
                                <a href="url.html#appvehicleupload" class="external" target="upload_frame" id="UploadLink0"><img style="width:50px;height:50px;" src="static/imageupload.png" /></a>

                            </div>

                            <div style="float: left; line-height: 50px;padding-left:10px;">
                                <a href="url.html#appvehicleupload" class="external" target="upload_frame" id="UploadLink">Upload Vehicle Photo</a>
                            </div>

                        </div>
                      
                    </div>
                    <iframe style="display:none;" name="upload_frame"></iframe>
                <input type="hidden" id="shine-vehicleimage" value="no-file.jpg" />
                </div>
        </div>

        <div class="toolbar toolbar-bottom  no-hairline no-shadow" style="height: 80px;background-color: #161b21">
            <div class="toolbar-inner" style="padding:20px;">
                <button class="button button-fill btn-addvehicle" id="btn-addvehicle" href="#">Finish Registration</button>
            </div>
        </div>
    </div>
`
    }
    ;
}

framework7Component.id = 'b49667e365';
export default framework7Component;