/** @jsx $jsx */
import { $jsx } from 'framework7'; // script must return/export component function

function framework7Component(props, _ref) {
  var $f7 = _ref.$f7,
      $on = _ref.$on;
  var phonenumber = localStorage.getItem('phone');
  var status = localStorage.getItem('status');
  $on('pageInit', function () {
    function getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];

        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }

      return "";
    }

    setTimeout(function () {
      $("#mainloading").fadeIn();
    }, 3500);

    if (status == "loggedin") {
      if ($f7.views.main.router.url == "/") {
        setTimeout(function () {
          $f7.views.main.router.navigate('/dynamic-route/page/home/', {
            ignoreCache: true
          });
        }, 4000);
      }
    } else {
      setTimeout(function () {
        $f7.views.main.router.navigate('/register/', {
          ignoreCache: true
        });
      }, 4000);
    }

    $(document).on("click", "#logo-btn", function () {
      if (status == "loggedin") {
        $f7.views.main.router.navigate('/dynamic-route/page/home/', {
          ignoreCache: true
        });
      } else {
        $f7.views.main.router.navigate('/register/', {
          ignoreCache: true
        });
      }
    });
  });
  $on('pageAfterOut', function () {// page has left the view
  }); // component function must return render function

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page no-swipeback" data-name="home" style="background-color: #161b21">
    <!-- Top Navbar -->

    <!-- Toolbar-->
 
    <!-- Scrollable page content-->
      <div class="page-content display-flex flex-direction-column justify-content-center">
          <div style="text-align:center">
            
             <a href="#" data-transition="f7-fade"> <img  style="max-width:80%" src="static/animatedlogo.gif" /></a>
             <div style="height: 20px;margin-top: 10px;">
                 <div style="text-align:center;font-size:10px;color:#ffd800;display:none;" id="mainloading"><img src="static/loader.gif" /></div>
             </div>
          </div>
      </div>
  </div>
`
    }
    ;
}

framework7Component.id = '78d60945ae';
export default framework7Component;