/** @jsx $jsx */
import { $jsx } from 'framework7'; // script must return/export component function

function framework7Component(props, _ref) {
  var $$ = _ref.$$,
      $f7 = _ref.$f7,
      $on = _ref.$on;
  var title = 'Component Page';
  var names = ['John', 'Vladimir', 'Timo'];

  var openAlert = function openAlert() {
    $f7.dialog.alert('Hello world!');
  };

  $on('pageInit', function () {});
  $on('pageAfterOut', function () {// page has left the view
  }); // component function must return render function

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page no-swipeback" data-name="home" style="background-color: #161b21">
    <!-- Top Navbar -->

    <!-- Toolbar-->
 
    <!-- Scrollable page content-->
      <div class="page-content display-flex flex-direction-column justify-content-center">
          <div style="text-align:center;height:100%;" id="map">
            
        
          </div>
      </div>
  </div>
`
    }
    ;
}

framework7Component.id = '09bcff33cc';
export default framework7Component;