/** @jsx $jsx */
import { $jsx } from 'framework7'; // script must return/export component function

function framework7Component(props, _ref) {
  var $f7 = _ref.$f7,
      $on = _ref.$on;
  var title = 'Component Page';
  var names = ['John', 'Vladimir', 'Timo'];
  var phonenumber = localStorage.getItem('phone');
  var calendarDefault;

  var openAlert = function openAlert() {
    $f7.dialog.alert('Hello world!');
  };

  $on('pageInit', function () {
    $("#shine-firstname").focus();

    function getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];

        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }

      return "";
    }

    function addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }

    calendarDefault = $f7.calendar.create({
      inputEl: '#shine-dobreg',
      maxDate: addDays(new Date(), 0),
      closeOnSelect: true,
      dateFormat: 'dd/mm/yyyy',
      inputReadOnly: false
    });

    function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var dt = new Date();
      dt.setMinutes(dt.getMinutes() + 30);
      var expires = "expires=" + d.toGMTString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function ValidateEmail(mail) {
      if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
        return true;
      }

      return false;
    }

    $("#shine-tel").val(phonenumber);
    $(document).on("click", "#btn-profile", function () {
      var isvalid = true;

      if ($("#shine-firstname").val() == undefined || $("#shine-firstname").val() == "") {
        isvalid = false;
        $(".shiner-form.firstname").css("border-color", "#ff0000");
      } else {
        $(".shiner-form.firstname").css("border-color", "");
      }

      if ($("#shine-lastname").val() == undefined || $("#shine-lastname").val() == "") {
        isvalid = false;
        $(".shiner-form.lastname").css("border-color", "#ff0000");
      } else {
        $(".shiner-form.lastname").css("border-color", "");
      }

      if (ValidateEmail($("#shine-email").val()) == false) {
        isvalid = false;
        $(".shiner-form.email").css("border-color", "#ff0000");
      } else {
        $(".shiner-form.email").css("border-color", "");
      }

      if ($("#shine-tel").val() == undefined || $("#shine-tel").val() == "") {
        isvalid = false;
        $(".shiner-form.contact").css("border-color", "#ff0000");
      } else {
        $(".shiner-form.contact").css("border-color", "");
      }

      if (isvalid == true) {
        $f7.preloader.show();
        $f7.request.post('https://appapi.shiners-uae.com/api/post.aspx', {
          phone: '' + phonenumber + '',
          firstname: '' + $(".regprofile #shine-firstname").val() + '',
          lastname: '' + $(".regprofile #shine-lastname").val() + '',
          email: '' + $(".regprofile #shine-email").val() + '',
          contact: '' + $("#shine-tel").val() + '',
          mode: 'update-profile',
          tempid: localStorage.getItem('tempid'),
          dob: $(".regprofile #shine-dobreg").val(),
          gender: $(".regprofile #shine-gender").val()
        }, function (data) {
          $f7.preloader.hide();
          var dataJSON = '' + data + '';
          var obj = JSON.parse(dataJSON, function (key, value) {
            return value;
          });

          if (obj.type == "updated") {
            setCookie('profiledone', 'True', '365');

            if (obj.location == "0" && obj.vehicle == "0") {
              $f7.views.main.router.navigate('/location/', {
                ignoreCache: true
              });
            } else if (obj.location == "0") {
              $f7.views.main.router.navigate('/location/', {
                ignoreCache: true
              });
            } else if (obj.vehicle == "0") {
              $f7.views.main.router.navigate('/vehicle/', {
                ignoreCache: true
              });
            } else {
              localStorage.setItem('status', 'loggedin');
              localStorage.setItem('type', 'existing');
              $f7.views.main.router.navigate('/dynamic/page/home/' + Math.random() + '/', {
                ignoreCache: true
              });
            }
          } else if (obj.type == "invaliddate") {
            $("#errorholder_profile").html("<span style='color:red'>Invalid Date.</span>");
          } else {
            $("#errorholder_profile").html("<span style='color:red'>Unable to Process.</span>");
          }
        });
      } else {}
    });
  });
  $on('pageAfterOut', function () {// page has left the view
  }); // component function must return render function

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page no-swipeback regprofile" data-name="about">

        <div class="page-content">
            <div class="shine-navbar shine-page-padding inner">


                <div class="row" style="position:relative;height:90px;">

                    <div class="col-50" style="position:absolute;bottom:0px;left:0px;">
                        <a href="#" class="link back">
                            <img src="static/back.png" style="height:15px;" />
                        </a>
                    </div>
                    <div class="col-50" style="position:absolute;bottom:4px;right:0px;"><img style="height:30px;float:right;" src="static/logo_small.png" /></div>
                </div>



            </div>

            <div class="shine-page-padding">

                <div class="shine-step" style="padding-top:115px;">
                    Welcome to Shiners.
                </div>
                <div class="shine-enter">
                    We need a little more information
                    from you below:
                </div>

                <div class="row" style="margin-top:20px;">

                    <div class="col-50">
                        <div class="shiner-form firstname">

                            <input type="text" name="shine-firstname" id="shine-firstname" placeholder="First Name" autocomplete="off" />
                        </div>
                    </div>

                    <div class="col-50">
                        <div class="shiner-form lastname">
                            <input type="text" name="shine-lastname" id="shine-lastname" placeholder="Last Name" autocomplete="off" />
                        </div>
                    </div>


                </div>

                <div class="row">

                    <div class="col-100">
                        <div class="shiner-form email">
                            <input type="email" name="shine-email" id="shine-email" placeholder="Email Address" autocomplete="off" />
                        </div>
                    </div>

                </div>

                <div class="row">

                    <div class="col-100">
                        <div class="shiner-form contact">
                            <input type="tel" name="shine-tel" id="shine-tel" placeholder="Phone Number" autocomplete="off" />
                        </div>
                    </div>

                </div>

                <div class="row">

                    <div class="col-100" style="padding-top:20px;">
                        <label>Gender</label><label style="float:right;font-size:12px;color:#808080">Optional</label>
                        <select class="shine-select" id="shine-gender" style="margin-top:10px;"><option value="None">Select Gender</option><option>Male</option><option>Female</option></select>

                    </div>

                </div>


                <div class="row">

                    <div class="col-100">
                        <div class="shiner-form dob">
                            <label>Date of Birth</label><label style="float:right;font-size:12px;color:#808080">Optional</label>
                            <input type="text" name="shine-email" id="shine-dobreg" autocomplete="off" placeholder="dd/mm/yyyy" />
                        </div>
                    </div>

                </div>


                <div class="row">

                    <div class="col-100" id="errorholder_profile">

                    </div>

                </div>

            </div>


        </div>

        <div class="toolbar toolbar-bottom  no-hairline no-shadow" style="        height: 80px;
        background-color: #161b21 !important;">
            <div class="toolbar-inner" style="padding:20px;">
                <button class="button button-fill" id="btn-profile" href="#">Next</button>
            </div>
        </div>

    </div>
`
    }
    ;
}

framework7Component.id = '2c574695fe';
framework7Component.style = "\n";
export default framework7Component;