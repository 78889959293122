/** @jsx $jsx */
import { $jsx } from 'framework7'; // script must return/export component function

function framework7Component(props, _ref) {
  var $f7 = _ref.$f7,
      $on = _ref.$on;
  var title = 'Component Page';
  var names = ['John', 'Vladimir', 'Timo'];

  var openAlert = function openAlert() {
    $f7.dialog.alert('Hello world!');
  };

  $on('pageInit', function () {
    localStorage.removeItem('type');
    localStorage.removeItem('verified');
    localStorage.removeItem('status');
    shiners_register();

    function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var dt = new Date();
      dt.setMinutes(dt.getMinutes() + 30);
      var expires = "expires=" + d.toGMTString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";";
    }

    $("#btn-register").click(function () {
      var isvalid = true;

      if ($("#telephonenumber").val() == undefined || $("#telephonenumber").val() == "") {
        isvalid = false;
      }

      if (isvalid == true) {
        $(".reg-input").css("border-color", ""); /// submit form

        var fullnumber = '' + $("#selectedcountry").html() + '' + $("#telephonenumber").val() + '';
        window.globalphonenumber = fullnumber;
        $f7.preloader.show();
        $f7.request.post('https://appapi.shiners-uae.com/api/post.aspx', {
          phone: '' + fullnumber + '',
          mode: 'pin'
        }, function (data) {
          $f7.preloader.hide();

          if (data == "new") {
            setCookie('phone', '' + fullnumber + '', '365');
            setCookie('type', 'new', '365');
            localStorage.setItem('phone', '' + fullnumber + '');
            localStorage.setItem('type', 'new');
            $f7.views.main.router.navigate('/otp/', {
              ignoreCache: true
            });
          } else if (data == "existing") {
            setCookie('phone', '' + fullnumber + '', '365');
            setCookie('type', 'existing', '365');
            localStorage.setItem('phone', '' + fullnumber + '');
            localStorage.setItem('type', 'existing');
            $f7.views.main.router.navigate('/otp/', {
              ignoreCache: true
            });
          } else if (data == "err") {
            $("#loginres").html("error while processing.");
          } else {
            $("#loginres").html(data);
          }
        }); /// end submit form
      }

      if (isvalid == false) {
        $("#telephonenumber").focus();
        $(".reg-input").css("border-color", "#ff0000");
      }
    });
    $(document).on("click", ".countrycode", function () {
      $("#selectedcountry").html('+' + $(this).data("code"));
      $("#selectedcounntryflag").html('<span  class="iti__flag iti__' + $(this).data("flag") + '" style="display:block;"></span>');
      $(".reg-popup").fadeOut('fast');
      $("#closebutton").fadeOut('fast');
    });
    $f7.request.json('https://appapi.shiners-uae.com/api/countries.aspx', function (data) {
      var tableHtml = '';

      for (var i = 0; i < data.length; i += 1) {
        tableHtml += '<tr class="countrycode" data-code="' + data[i][2] + '" data-flag="' + data[i][1] + '"><td style="padding-right:10px;" class="flag"><span  class="iti__flag iti__' + data[i][1] + '" style="display:block;"></span><td class="code" style="color:#000000">' + data[i][2] + '</td> <td class="name" style="color:#000000">' + data[i][0] + ' </td> </tr><tr><td colspan="3" class="hr"></td></tr>';
      }

      $('.countries-list table').html(tableHtml);
    });
  });
  $on('pageAfterOut', function () {// page has left the view
  }); // component function must return render function

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
    <div class="page no-swipeback" data-name="about">
        <div class="navbar">


        </div>
        <div class="page-content display-flex flex-direction-column justify-content-center">
      
            <div style="padding-right:50px;padding-left:50px;">
                <div class="reg-logo" style="text-align:center">

                    <img style="max-width:200px;" src="static/logo.png" />
                </div>

                <div class="reg-welcome">
                    Welcome To Shiners,<br />
                    your premium car washing
                    service at your door step!
                </div>

                <div class="reg-please">
                    Please enter your mobile number
                    below to register your account.
                </div>

                <div style="position: absolute; right: 50px; top: 40px; height: 40px;display:none;" id="closebutton">
                    <a href="#" id="closecountry">
                        <img src="static/cancel.png" style=" height: 25px;" />
                    </a>
                </div>

                <div class="reg-popup" style="display: none;overflow-y: scroll;">

                    <div class="country-list">

                        <div class="countries-list">
                            <table cellpadding="0" cellpadding="0">
                                <tbody>
                                    <tr>
                                        <td class="label-cell">Type</td>
                                        <td class="numeric-only" id="ac_2_type"></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="reg-input">

                    <table>
                        <tr>
                            <td class="reg-flag" id="selectedcounntryflag">
                                <a class="rcp uae reg-flag-picker">

                                </a>
                            </td>
                            <td class="reg-code" id="selectedcountry">+971</td>
                            <td class="reg-number">

                                <input type="tel" placeholder="50 123456"  pattern="^[0-9]*$" data-error-message="Only numbers please!" id="telephonenumber" required validate />

                            </td>
                        </tr>
                    </table>


                </div>
                <div class="reg-button">
                    <button class="button button-fill" id="btn-register" href="#">Register</button>
                    <div id="loginres"></div>
                </div>

            </div>
        </div>
    </div>
`
    }
    ;
}

framework7Component.id = '8591ce693d';
framework7Component.style = "\n\n \n";
export default framework7Component;