
import HomePage from '../pages/home.f7.html';
import HomePage2 from '../pages/home2.f7.html';
import AboutPage from '../pages/about.f7.html';
import FormPage from '../pages/form.f7.html';
import usrRegister from '../pages/register.f7.html';
import userOTP from '../pages/otp.f7.html';
import usrProfile from '../pages/profile.f7.html';
import usrLocation from '../pages/location.f7.html';
import usrVehicle from '../pages/vehicle.f7.html';

import DynamicRoutePage from '../pages/dynamic-route.f7.html';
import RequestAndLoad from '../pages/request-and-load.f7.html';
import NotFoundPage from '../pages/404.f7.html';

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

var phonenumber = localStorage.getItem('phone');
var status = localStorage.getItem('status');
localStorage.setItem('tempid', Math.random());


function GetTp() {
    return localStorage.getItem('phone');
}

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/about/',
    component: AboutPage,
    },
    {
        path: '/home2/',
        component: HomePage2,
    },
  {
    path: '/form/',
    component: FormPage,
  },
    {
        path: '/register/',
        component: usrRegister,
    },
    {
        path: '/otp/',
        component: userOTP,
    },
    {
        path: '/profile/',
        component: usrProfile,
    },
    {
        path: '/location/',
        component: usrLocation,
    },
    {
        path: '/vehicle/',
        component: usrVehicle,
    },
    {
        path: '/homepage/:tick/',
        componentUrl: 'https://appapi.shiners-uae.com/api/dynamic.aspx?page=home&phone=' + btoa(phonenumber) + '&tick={{tick}}&' + localStorage.getItem('tempid')+'',
        cache: false
    },
  {
    path: '/dynamic-route/page/:pageid/',
      componentUrl: 'https://appapi.shiners-uae.com/api/dynamic.aspx?page={{pageid}}&phone=' + btoa(phonenumber) + '&tempid=' + localStorage.getItem('tempid')+'',
      cache: false
    },
    {
        path: '/dynamic/page/:pageid/:tick/',
        componentUrl: 'https://appapi.shiners-uae.com/api/dynamic.aspx?page={{pageid}}&phone=' + btoa(localStorage.getItem('phone')) + '&tick={{tick}}&tempid=' + localStorage.getItem('tempid') +'',
        cache: false
    },
    {
        path: '/dynamic-route/page/:pageid/:source/:tick/',
        component: 'https://appapi.shiners-uae.com/api/dynamic.aspx?page={{pageid}}&phone=' + btoa(localStorage.getItem('phone')) + '&source={{source}}&tick={{tick}}&tempid=' + localStorage.getItem('tempid') +'',
        cache: false
    },
    {
        path: '/backend/dynamic-route/page/:pageid/:tick/',
        component: 'https://appapi.shiners-uae.com/api/dynamic.aspx?page=backend/{{pageid}}&phone=' + btoa(localStorage.getItem('phone')) + '&tick={{tick}}&tempid=' + localStorage.getItem('tempid') + '',
        cache: false
    },
    {
        path: '/checkout/page/:pageid/:vehicle/:location/:tick/',
        component: 'https://appapi.shiners-uae.com/api/dynamic.aspx?page={{pageid}}&phone=' + btoa(localStorage.getItem('phone')) + '&vehicle={{vehicle}}&location={{location}}&tick={{tick}}&tempid=' + localStorage.getItem('tempid') +'',
        cache: false
    },
    {
        path: '/checkout/page/:pageid/:vehicle/:location/:tick/:bookingid/rebook/',
        component: 'https://appapi.shiners-uae.com/api/dynamic.aspx?page={{pageid}}&phone=' + btoa(localStorage.getItem('phone')) + '&vehicle={{vehicle}}&location={{location}}&tick={{tick}}&mode=rebook&bookingid={{bookingid}}&tempid=' + localStorage.getItem('tempid') +'',
        cache: false
    },
    {
        path: '/bookingcompleted/:bookingid/:tick/',
        component: 'https://appapi.shiners-uae.com/api/dynamic.aspx?page=confirmation&phone=' + btoa(localStorage.getItem('phone')) + '&&tick={{tick}}&bookingid={{bookingid}}&tempid=' + localStorage.getItem('tempid') +'',
        cache: false
    },
    {
        path: '/booking/:bookingid/',
        component: 'https://appapi.shiners-uae.com/api/dynamic.aspx?page=bookingdetails&phone=' + btoa(localStorage.getItem('phone')) + '&bookingid={{bookingid}}&tempid=' + localStorage.getItem('tempid') +'',
        cache: false
    },
    {
        path: '/select-vehicle/:locationid/:tick/',
        component: 'https://appapi.shiners-uae.com/api/dynamic.aspx?page=vehicles&phone=' + btoa(localStorage.getItem('phone')) + '&tick={{tick}}&locationid={{locationid}}&mode=selectforcheckout&tempid=' + localStorage.getItem('tempid') +'',
        cache: false
    },
    {
        path: '/select-location/:vehicleid/:tick/',
        component: 'https://appapi.shiners-uae.com/api/dynamic.aspx?page=locations&phone=' + btoa(localStorage.getItem('phone')) + '&tick={{tick}}&vehicleid={{vehicleid}}&mode=selectforcheckout&tempid=' + localStorage.getItem('tempid') +'',
        cache: false
    },
    {
        path: '/paymenturl/:bookingid/:tick/',
        component: 'https://appapi.shiners-uae.com/api/dynamic.aspx?page=pay&phone=' + btoa(localStorage.getItem('phone')) + '&tick={{tick}}&bookingid={{bookingid}}&tempid=' + localStorage.getItem('tempid') + '',
        cache: false
    },
    {
        path: '/dynamic-route/blog/:blogId/post/:postId/',
        component: DynamicRoutePage,
    },
  {
    path: '/request-and-load/user/:userId/',
    async: function ({ router, to, resolve }) {
      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = to.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            props: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
      component: 'https://appapi.shiners-uae.com/api/dynamic.aspx',
  },
];

export default routes;